import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useState } from 'react';
import { MenuItemCategoryCard } from './TopLevelCategoryCards';
import useMenuData from './useMenuData';

type DeviceState = {
  batteryState: {
    batteryLevel: number,
    isCharging: boolean,
  }
}

type AndroidInterface = {
  testFunction: (param: string) => string;
  getDeviceState: () => string
};

// move to window?
declare var Android: AndroidInterface;

export default function MenuCategories() {
  const { categories } = useMenuData();
  const categoryArray = Object.values(categories);
  const [apiResponse, setApiResponse] = useState(null as null | string);

  const dispatch = async () => {
    setApiResponse(null);
    const response = await fetch('api/getMenuAsync');
    console.log('response', response);
    const jsonData = await response.text();
    console.log(jsonData);

    setApiResponse(JSON.stringify(jsonData));
  };

  const [syncMenuResponse, setSyncMenuResponse] = useState(null as null | string);

  const dispatchSyncMenu = async () => {
    setSyncMenuResponse(null);
    const response = await fetch('api/syncMenuData');
    console.log('response', response);
    const jsonData = await response.text();
    console.log(jsonData);

    setSyncMenuResponse(JSON.stringify(jsonData));
  };

  const [androidFunctionResponse, setAndroidFunctionResponse] = useState(null as null | string | DeviceState);

  const invokeAndroidfunction = () => {
    console.log('invoking Android function 2');
    try {
      // @ts-ignore
      if (!window || !window.Android || !window.Android.hasOwnProperty("getDeviceState")) {
        throw Error("Android.getDeviceState does not exist on window")
      }

      const result = Android.getDeviceState();
      console.log(`result: ${JSON.stringify(result)}`);
      const parsed: DeviceState = JSON.parse(result)
      setAndroidFunctionResponse(parsed);
    } catch (e) {
      console.error(`global Android.getDeviceState cannot be invoked: ${e}`);
      setAndroidFunctionResponse(`Android.getDeviceState failed, ${e?.toString()}`);
    }
  };

  return (
    <Box sx={{ height: '100vh' }}>
      <Box sx={{ py: 3 }}>
        <button onClick={dispatch}>click to fetch menu</button>
        <Box sx={{ py: 1 }}>
          response: <pre>{apiResponse}</pre>
        </Box>
      </Box>
      <Box sx={{ py: 3 }}>
        <button onClick={dispatchSyncMenu}>click to sync menu</button>
        <Box sx={{ py: 1 }}>
          response: <pre>{syncMenuResponse}</pre>
        </Box>
      </Box>
      <Box sx={{ py: 3 }}>
        <button onClick={invokeAndroidfunction}>click to get device state</button>
        <Box sx={{ py: 1 }}>
          response: <pre>{JSON.stringify(androidFunctionResponse)}</pre>
        </Box>
      </Box>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {categoryArray.map(({ key, route, displayLabel }) => (
          <Grid key={`key-${key}`} xs={6} display="flex" justifyContent="center" alignItems="center">
            <MenuItemCategoryCard categoryLabel={displayLabel} categoryRoute={`/menu/${route}`} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
