export type BeverageMenuItem = {
  Category: string; // Beer, Wine, Spirits, Specialty Cocktails
  Price: number | '';
  Hidden: string; // '' || 'yes'
  Name: string;
  Type: string;
  Manufacturer: string;
  Origin: string;
  Country: string;
  ABV: number | '';
  DisplayGrouping: string;
  Image: string;
  Description: string;
};

const mockData: BeverageMenuItem[] = [
  {
    Category: 'Beer',
    Price: 4.5,
    Hidden: '',
    Name: 'Blue Moon Belgian White',
    Type: 'Belgian',
    Manufacturer: 'MillerCoors',
    Origin: 'Golden, Colorado',
    Country: 'USA',
    ABV: '',
    DisplayGrouping: 'On Tap',
    Image: '',
    Description:
      'Moderate body,smooth and creamy. burst of sweetness at first from the malt and coriander,hints of citrus peel and light pepper corns',
  },
  {
    Category: 'Beer',
    Price: 4.5,
    Hidden: '',
    Name: 'Boulevard Wheat',
    Type: 'Wheat',
    Manufacturer: 'Boulevard Brewing Company',
    Origin: 'Kansas City, Missouri',
    Country: 'USA',
    ABV: 4.4,
    DisplayGrouping: 'On Tap',
    Image: '',
    Description: 'A lively, refreshing ale with a natural citrusly flavor and distinctive cloudy appearance.',
  },
  {
    Category: 'Beer',
    Price: 5,
    Hidden: '',
    Name: 'Breakfast Stout',
    Type: 'American Double / Imperial Stout',
    Manufacturer: 'Founders Brewing Company',
    Origin: 'Grand Rapids Michigan',
    Country: 'USA',
    ABV: 8.3,
    DisplayGrouping: 'Bocks & Stouts',
    Image: '',
    Description:
      'Brewed with an abundance of flaked oats, bitter and imported chocolates, and two types of coffee, this stout has an intense fresh-roasted java nose topped with a frothy, cinnamon-colored head.',
  },
  {
    Category: 'Beer',
    Price: 4,
    Hidden: '',
    Name: 'Bud Light',
    Type: 'American Lager',
    Manufacturer: 'Anheuser-Busch',
    Origin: 'St. Louis, Missouri',
    Country: 'USA',
    ABV: '',
    DisplayGrouping: 'Domestics',
    Image: '',
    Description: '',
  },
  {
    Category: 'Beer',
    Price: 3.5,
    Hidden: '',
    Name: 'Bud Light',
    Type: 'Pilsner',
    Manufacturer: 'Anheuser-Busch',
    Origin: 'St. Louis, Missouri',
    Country: 'USA',
    ABV: '',
    DisplayGrouping: 'On Tap',
    Image: '',
    Description: '',
  },
  {
    Category: 'Beer',
    Price: 4,
    Hidden: '',
    Name: 'Budweiser',
    Type: 'American Lager',
    Manufacturer: 'Anheuser-Busch',
    Origin: 'St. Louis, Missouri',
    Country: 'USA',
    ABV: '',
    DisplayGrouping: 'Domestics',
    Image: '',
    Description: '',
  },
  {
    Category: 'Beer',
    Price: 3.5,
    Hidden: '',
    Name: 'Budweiser',
    Type: 'Pilsner',
    Manufacturer: 'Anheuser-Busch',
    Origin: 'St. Louis, Missouri',
    Country: 'USA',
    ABV: '',
    DisplayGrouping: 'On Tap',
    Image: '',
    Description: '',
  },
  {
    Category: 'Beer',
    Price: 4,
    Hidden: '',
    Name: 'Busch Light',
    Type: 'American Lager',
    Manufacturer: 'Anheuser-Busch',
    Origin: 'St. Louis, Missouri',
    Country: 'USA',
    ABV: '',
    DisplayGrouping: 'Domestics',
    Image: '',
    Description: '',
  },
  {
    Category: 'Beer',
    Price: 3.5,
    Hidden: '',
    Name: 'Busch Light',
    Type: 'Pilsner',
    Manufacturer: 'Anheuser-Busch',
    Origin: 'St. Louis, Missouri',
    Country: 'USA',
    ABV: '',
    DisplayGrouping: 'On Tap',
    Image: '',
    Description: '',
  },
  {
    Category: 'Beer',
    Price: 7,
    Hidden: '',
    Name: 'Clementine Hibiscus Seltzer Water',
    Type: 'Hard Seltzer Water',
    Manufacturer: 'Bon & Viv',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Seltzer Water',
    Image: '',
    Description: '',
  },
  {
    Category: 'Beer',
    Price: 4,
    Hidden: '',
    Name: 'Coors Light',
    Type: 'American Lager',
    Manufacturer: 'MillerCoors',
    Origin: 'Golden, Colorado',
    Country: 'USA',
    ABV: '',
    DisplayGrouping: 'Domestics',
    Image: '',
    Description: '',
  },
  {
    Category: 'Beer',
    Price: 4,
    Hidden: '',
    Name: 'Corona Extra',
    Type: 'Adjunct Lager',
    Manufacturer: 'Anheuser-Busch',
    Origin: 'St. Louis, Missouri',
    Country: 'USA',
    ABV: 4.5,
    DisplayGrouping: 'Lagers',
    Image: '',
    Description: '',
  },
  {
    Category: 'Beer',
    Price: 7,
    Hidden: '',
    Name: 'Cranberry Seltzer Water',
    Type: 'Hard Seltzer Water',
    Manufacturer: 'Bon & Viv',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Seltzer Water',
    Image: '',
    Description: '',
  },
  {
    Category: 'Beer',
    Price: 5,
    Hidden: 'yes',
    Name: 'Dead Guy Ale',
    Type: 'Maibock / Helles Bock',
    Manufacturer: 'Rogue Ales',
    Origin: 'Newport, Oregon',
    Country: 'USA',
    ABV: 6.8,
    DisplayGrouping: 'Bocks & Stouts',
    Image: '',
    Description: 'Dead Guy Ale is deep honey in color with a malty aroma, rich hearty flavor and a well balanced finish.',
  },
  {
    Category: 'Beer',
    Price: 8,
    Hidden: '',
    Name: "Dragon's Milk",
    Type: 'American Double / Imperial Stout',
    Manufacturer: 'New Holland Brewing Company',
    Origin: 'Holland, Michigan',
    Country: 'USA',
    ABV: 11,
    DisplayGrouping: 'Bocks & Stouts',
    Image: '',
    Description: 'A stout with roasty malt character intermingled with deep vanilla tones, all dancing in an oak bath.',
  },
  {
    Category: 'Beer',
    Price: 4,
    Hidden: '',
    Name: "Ellie's Brown Ale",
    Type: 'American Brown Ale',
    Manufacturer: 'Avery Brewing Company',
    Origin: 'Boudler, Colorado',
    Country: 'USA',
    ABV: 5.5,
    DisplayGrouping: 'Ales',
    Image: '',
    Description: 'Chocolate malt gives this beer a brown sugar maltiness with hints of vanilla and nuts, with subtle hoppiness.',
  },
  {
    Category: 'Beer',
    Price: 5,
    Hidden: '',
    Name: 'Fat Tire',
    Type: 'Ameri-Belgo Style Ale',
    Manufacturer: 'New Belgium',
    Origin: 'Fort Collins, Colorado',
    Country: 'USA',
    ABV: 5.2,
    DisplayGrouping: 'Ales',
    Image: '',
    Description:
      'A toasty malt, gentle sweetness, and flash of fresh hop bitterness. The malt and hops are perfectly balanced with a sweet biscuity and caramel malt, subtle notes of fresh fennel and green apple.',
  },
  {
    Category: 'Beer',
    Price: 4.5,
    Hidden: '',
    Name: 'Goose Island IPA',
    Type: 'IPA',
    Manufacturer: 'Anheuser-Busch',
    Origin: 'St. Louis, Missouri',
    Country: 'USA',
    ABV: 5.9,
    DisplayGrouping: 'On Tap',
    Image: '',
    Description: 'This IPA has strong bitterness with grapefruit, pine and floral notes.',
  },
  {
    Category: 'Beer',
    Price: 7,
    Hidden: '',
    Name: 'Grapefruit Hard Seltzer Water',
    Type: 'Hard Seltzer Water',
    Manufacturer: 'Bon & Viv',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Seltzer Water',
    Image: '',
    Description: '',
  },
  {
    Category: 'Beer',
    Price: 4,
    Hidden: '',
    Name: 'Guinness',
    Type: 'Dry Stout',
    Manufacturer: 'Guiness Ltd.',
    Origin: 'Dublin, Ireland',
    Country: 'Ireland',
    ABV: 5.6,
    DisplayGrouping: 'Bocks & Stouts',
    Image: '',
    Description: 'Sweet smelling with a coffee and maltly nose. Perfect balance of bitter and sweet with malt and raosted characters.',
  },
  {
    Category: 'Beer',
    Price: 4.5,
    Hidden: '',
    Name: 'Guinness Extra Stout',
    Type: 'Extra Dry Stout',
    Manufacturer: 'Guiness Ltd.',
    Origin: 'Dublin, Ireland',
    Country: 'Ireland',
    ABV: 5.6,
    DisplayGrouping: 'Bocks & Stouts',
    Image: '',
    Description:
      'A roast character with subtle fermentation fruitiness, rounded bitter and sweet flavor. Smooth with a slight bite and dry finish. Distinctively black full bodied with rich creamy head.',
  },
  {
    Category: 'Beer',
    Price: 4,
    Hidden: '',
    Name: 'Heineken',
    Type: 'Euro Pale Lager',
    Manufacturer: 'Heineken Nederland',
    Origin: 'Amsterdam, Netherlands',
    Country: 'Netherlands',
    ABV: 5,
    DisplayGrouping: 'Lagers',
    Image: '',
    Description: 'Smooth, nicley blended bitterness,clean and fresh with a shade of fruitiness',
  },
  {
    Category: 'Beer',
    Price: 4,
    Hidden: '',
    Name: 'Lucky Buddha',
    Type: 'Asian Style Lager',
    Manufacturer: 'The Lucky Drink Company',
    Origin: 'Sydney, Australia',
    Country: 'Australia',
    ABV: 4.8,
    DisplayGrouping: 'Lagers',
    Image: '',
    Description:
      'A true Asian style lager with lots of flavour and a crisp,clean finish. It is a fusion of the finest quality malt,hops,rice flakes and pristine water. provides a natural fit with Asian food flavours, but shines with any cuisine.',
  },
  {
    Category: 'Beer',
    Price: 3.5,
    Hidden: '',
    Name: 'Michelob Golden',
    Type: 'Pilsner',
    Manufacturer: 'Anheuser-Busch',
    Origin: 'St. Louis, Missouri',
    Country: 'USA',
    ABV: '',
    DisplayGrouping: 'On Tap',
    Image: '',
    Description: '',
  },
  {
    Category: 'Beer',
    Price: 4,
    Hidden: '',
    Name: 'Michelob Ultra',
    Type: 'Amber Lager',
    Manufacturer: 'Anheuser-Busch',
    Origin: 'St. Louis, Missouri',
    Country: 'USA',
    ABV: '',
    DisplayGrouping: 'Domestics',
    Image: '',
    Description: '',
  },
  {
    Category: 'Beer',
    Price: 4,
    Hidden: '',
    Name: 'Miller Lite',
    Type: 'Pale Lager',
    Manufacturer: 'MillerCoors',
    Origin: 'Golden, Colorado',
    Country: 'USA',
    ABV: '',
    DisplayGrouping: 'Domestics',
    Image: '',
    Description: '',
  },
  {
    Category: 'Beer',
    Price: 7,
    Hidden: '',
    Name: 'Modelo Especial',
    Type: 'Adjunct Lager',
    Manufacturer: 'Anheuser-Busch',
    Origin: 'St. Louis, Missouri',
    Country: 'USA',
    ABV: 4.4,
    DisplayGrouping: 'Lagers',
    Image: '',
    Description: '',
  },
  {
    Category: 'Beer',
    Price: 4.5,
    Hidden: '',
    Name: 'Newcastle Brown Ale',
    Type: 'English Brown Ale',
    Manufacturer: 'Heineken Nederland',
    Origin: 'Amsterdam, Netherlands',
    Country: 'Netherlands',
    ABV: 4.7,
    DisplayGrouping: 'Ales',
    Image: '',
    Description: 'A full-bodied and smooth ale, showing restrained caramel and notes of bananas and dried fruit.',
  },
  {
    Category: 'Beer',
    Price: 5,
    Hidden: '',
    Name: 'Nitro Milk Stout',
    Type: 'Stout',
    Manufacturer: 'Left Hand Brewing Company',
    Origin: 'Longmont, Colorado',
    Country: 'USA',
    ABV: 6,
    DisplayGrouping: 'Bocks & Stouts',
    Image: '',
    Description:
      'Milk Stout Nitro cascades beautifully, building a tight, thick head. The aroma is of brown sugar and vanilla cream, with hints of roasted coffee. Initial roasty, mocha flavors rise up, with slight hops and roast bitterness in the finish.',
  },
  {
    Category: 'Beer',
    Price: 4,
    Hidden: '',
    Name: "Not Your Father's Root Beer",
    Type: 'Herbed / Spiced Ale',
    Manufacturer: 'Small Town Brewery',
    Origin: 'Wauconda, Illinois',
    Country: 'USA',
    ABV: 5.9,
    DisplayGrouping: 'Ales',
    Image: '',
    Description:
      'A category-defining craft specialty ale brewed with unique spices that masterfully blends hints of sarsaparilla, wintergreen, anise, and vanilla. A smooth and balanced Ale, NYFRB has broad appeal â€“ from discerning craft beer drinkers to non-beer drinkers.',
  },
  {
    Category: 'Beer',
    Price: 7,
    Hidden: '',
    Name: 'Pear Elderflower Seltzer Water',
    Type: 'Hard Seltzer Water',
    Manufacturer: 'Bon & Viv',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Seltzer Water',
    Image: '',
    Description: '',
  },
  {
    Category: 'Beer',
    Price: 16,
    Hidden: '',
    Name: 'Pitcher - Craft/Import',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'On Tap',
    Image: '',
    Description: "Boulevard Wheat, Goose Island IPA, Blue Moon, Leinenkugel's Summer Shandy",
  },
  {
    Category: 'Beer',
    Price: 12,
    Hidden: '',
    Name: 'Pitcher - Domestic',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'On Tap',
    Image: '',
    Description: 'Michelob Golden, Budweiser, Bud Lite, Michelob Golden',
  },
  {
    Category: 'Beer',
    Price: 4.5,
    Hidden: '',
    Name: 'Prairie Path Two Brothers',
    Type: 'Golden Ale (Low Gluten)',
    Manufacturer: 'Two Brothers Artisan Brewing',
    Origin: 'Warrenville, Illinois',
    Country: 'USA',
    ABV: 5.1,
    DisplayGrouping: 'Ales',
    Image: '',
    Description: 'A light color and a complex malt character that is paired with Saaz and Golding hops.  Crafted to remove Gluten!',
  },
  {
    Category: 'Beer',
    Price: 6,
    Hidden: '',
    Name: 'PranQster',
    Type: 'Belgian Style Golden',
    Manufacturer: 'Northcoast Brewing Co.',
    Origin: 'Fort Bragg, California',
    Country: 'USA',
    ABV: 7.6,
    DisplayGrouping: 'Ales',
    Image: '',
    Description: 'A floral nose, a full fruity flavor, and a clean finish.',
  },
  {
    Category: 'Beer',
    Price: 4.5,
    Hidden: '',
    Name: "Redd's Apple Ale",
    Type: 'Cider',
    Manufacturer: "Redd's Brewing Company",
    Origin: 'Milwaukee, Wisconsin',
    Country: 'USA',
    ABV: 5,
    DisplayGrouping: 'Ciders',
    Image: '',
    Description: '',
  },
  {
    Category: 'Beer',
    Price: 5,
    Hidden: 'yes',
    Name: "Rod's Cream Ale",
    Type: 'Cream Ale',
    Manufacturer: 'Public House Brewing',
    Origin: 'Rolla, Missouri',
    Country: 'USA',
    ABV: 5,
    DisplayGrouping: 'Ales',
    Image: '',
    Description:
      'Prominent malt notes complimented by a flaked maize aroma that bubbles up through the carbonation, elegantly balanced by noble hops.',
  },
  {
    Category: 'Beer',
    Price: 4.5,
    Hidden: '',
    Name: 'Sam Adams Boston Lager',
    Type: 'Vienna Lager',
    Manufacturer: 'Boston Beer Company',
    Origin: 'Pottsville, Pennsylvannia',
    Country: 'USA',
    ABV: 5,
    DisplayGrouping: 'Lagers',
    Image: '',
    Description: 'Lively unfiltered White Ale that gets a kick from a bright blend of sprng spices.',
  },
  {
    Category: 'Beer',
    Price: 4.5,
    Hidden: '',
    Name: 'Sam Adams Cold Snap',
    Type: 'Belgian White Ale',
    Manufacturer: 'Sam Adams',
    Origin: 'Saint Joe, Missouri',
    Country: 'USA',
    ABV: 5.3,
    DisplayGrouping: 'On Tap',
    Image: '',
    Description:
      'A lively unfiltered white ale with a kick of bright spring spices. Orange peel and plum add subtle sweetness and coriander gives a peppery bite.',
  },
  {
    Category: 'Beer',
    Price: 4.5,
    Hidden: 'yes',
    Name: 'Stella Artois',
    Type: 'Euro Pale Lager',
    Manufacturer: 'Anheuser-Busch',
    Origin: 'St. Louis, Missouri',
    Country: 'Belgian',
    ABV: 5,
    DisplayGrouping: 'Lagers',
    Image: '',
    Description: '',
  },
  {
    Category: 'Beer',
    Price: 5,
    Hidden: '',
    Name: 'Stone Ruination IPA',
    Type: 'American Double / Imperial IPA',
    Manufacturer: 'Stone Brewing',
    Origin: 'Escondido, California',
    Country: 'USA',
    ABV: 8.2,
    DisplayGrouping: "IPA's",
    Image: '',
    Description:
      "The balance shifts a bit more toward the tastes of hops,with tropical fruits and a hint of melon and citrus following close behind. the pine notes are inense, and the beer's light malt allows the bitterness to stand out.",
  },
  {
    Category: 'Beer',
    Price: 5,
    Hidden: '',
    Name: 'The Calling',
    Type: 'American Double / Imperial IPA',
    Manufacturer: 'Boulevard Brewing Company',
    Origin: 'Kansas City, Missouri',
    Country: 'USA',
    ABV: 8.5,
    DisplayGrouping: "IPA's",
    Image: '',
    Description:
      'A heavily hopped beer bursting with tropical fruit and pine hop aromas and  a slightly sweet malt character, tapering to a crisp, dry finish.',
  },
  {
    Category: 'Cocktails',
    Price: 8,
    Hidden: '',
    Name: 'Adult Root Beer Float',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Signature Cocktails',
    Image: '',
    Description: "Not Your Father's Root Beer Beer, Brown Sugar Bourbon Ice Cream",
  },
  {
    Category: 'Cocktails',
    Price: 12,
    Hidden: '',
    Name: 'Annie Oakley',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Signature Cocktails',
    Image: '',
    Description: 'Ladies Who Shoot Their Lunch Chardonnay, Peaches and Blueberries',
  },
  {
    Category: 'Cocktails',
    Price: 7,
    Hidden: '',
    Name: 'BBQ Bloody Mary',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Signature Cocktails',
    Image: '',
    Description: "Tito's Vodka, Signature Bentley's Reserve BBQ Bloody Mary Mix, Garnished with a Rib",
  },
  {
    Category: 'Cocktails',
    Price: 7,
    Hidden: '',
    Name: 'Calamity Jane',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Signature Cocktails',
    Image: '',
    Description: 'Calamity Gin, Lemon, Rosemary Simple Syrup, Soda Water',
  },
  {
    Category: 'Cocktails',
    Price: 9,
    Hidden: '',
    Name: 'Coffefe',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Signature Cocktails',
    Image: '',
    Description: "Tito's Vodka, Khalua, Cold Brew, Cream",
  },
  {
    Category: 'Cocktails',
    Price: 7,
    Hidden: '',
    Name: 'Cucumber Mule',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Missouri Mules',
    Image: '',
    Description: 'Svedka Cucumber Lime Vodka, Lime Juice, Ginger Beer',
  },
  {
    Category: 'Cocktails',
    Price: 6,
    Hidden: '',
    Name: 'FrosÃ©',
    Type: 'Buffalo Blush or Agusta Vignoles',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Signature Cocktails',
    Image: '',
    Description: 'Frozen Wine Slush',
  },
  {
    Category: 'Cocktails',
    Price: 7,
    Hidden: '',
    Name: 'Hootenanny',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Signature Cocktails',
    Image: '',
    Description: 'Drambuie, Grapefruit Juice, Splash of Wheat Beer',
  },
  {
    Category: 'Cocktails',
    Price: 6,
    Hidden: '',
    Name: 'Jim & Ruby',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Signature Cocktails',
    Image: '',
    Description: 'Jim Beam, Signature Hot Ruby Spiced Cranberry Citrus Juice',
  },
  {
    Category: 'Cocktails',
    Price: 7,
    Hidden: '',
    Name: 'Kentucky Mule',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Missouri Mules',
    Image: '',
    Description: "Russell's Reserve Whiskey, Lemon, Rosemary Simple Syrup, Ginger Beer",
  },
  {
    Category: 'Cocktails',
    Price: 7,
    Hidden: '',
    Name: 'Malibu Mule',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Missouri Mules',
    Image: '',
    Description: 'Malibu Coconut Rum, Lime Juice, Ginger Beer',
  },
  {
    Category: 'Cocktails',
    Price: 6,
    Hidden: 'yes',
    Name: 'Mimosa',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Signature Cocktails',
    Image: '',
    Description: 'Champagne and Orange Juice',
  },
  {
    Category: 'Cocktails',
    Price: 9,
    Hidden: '',
    Name: 'Rusty Nail',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Signature Cocktails',
    Image: '',
    Description: "Maker's Mark, Drambuie, Lemon Infusion",
  },
  {
    Category: 'Cocktails',
    Price: 7,
    Hidden: 'yes',
    Name: 'Spiced Pear Mimosa',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Signature Cocktails',
    Image: '',
    Description: 'Spiced Pear Juice, Champagne',
  },
  {
    Category: 'Cocktails',
    Price: 6,
    Hidden: '',
    Name: 'Strawberry Basil French 75',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Signature Cocktails',
    Image: '',
    Description: "Gilby's Gin, Champagne, Strawberry Puree, Basil Infusion",
  },
  {
    Category: 'Cocktails',
    Price: 7,
    Hidden: '',
    Name: 'Strawberry Basil Mule',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Missouri Mules',
    Image: '',
    Description: 'Strawberry Beefeaters Gin, Lemon, Basil Simple Syrup, Ginger Beer',
  },
  {
    Category: 'Cocktails',
    Price: 9,
    Hidden: '',
    Name: 'The Bourbon Cowboy',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Signature Cocktails',
    Image: '',
    Description: 'Stetson Kentucky Straight Bourbon, Southern Comfort, Fresh Squeeze Lemonade',
  },
  {
    Category: 'Cocktails',
    Price: 9,
    Hidden: '',
    Name: 'The Revolver',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Signature Cocktails',
    Image: '',
    Description: "Trail's End Bourbon, Bailey's, Angostura Bitters, Orange Infusion",
  },
  {
    Category: 'Cocktails',
    Price: 9,
    Hidden: '',
    Name: 'Vodka Lemonade',
    Type: 'Regular or Strawberry',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Signature Cocktails',
    Image: '',
    Description: "Tito's Vodka, Fresh Squeezed Lemonade",
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: '99 Bananas',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Liqueur',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: 'yes',
    Name: 'Absolut',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Vodka',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Amaretto',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Liqueur',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Asbach 3 Year',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: 'Germany',
    ABV: '',
    DisplayGrouping: 'Brandy',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Bacardi',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Rum',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Baileys',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Liqueur',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: "Basil Hayden's",
    Type: 'Kentucky Bourbon Whisky',
    Manufacturer: 'Beam Suntory',
    Origin: 'Chicago, Illinois',
    Country: 'USA',
    ABV: '',
    DisplayGrouping: 'Whiskey',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Beefeater London Strawberry',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Gin',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Bibb & Tucker',
    Type: 'Small Batch Bourbon',
    Manufacturer: 'Deutsch Family Wine & Spirits',
    Origin: 'Stamford, Connecticut',
    Country: 'USA',
    ABV: '',
    DisplayGrouping: 'Whiskey',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Black Velvet',
    Type: 'Canadian Whiskey',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Whiskey',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Blue Curacao',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Liqueur',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Bombay Sapphire',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Gin',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Buffalo Trace',
    Type: 'Kentucky Straight Bourbon',
    Manufacturer: 'Buffalo Trace Distillery',
    Origin: 'Franklin, Kentucky',
    Country: 'USA',
    ABV: '',
    DisplayGrouping: 'Whiskey',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Calamity Gin',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Gin',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Canadian Club',
    Type: 'Canadian Whiskey',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Whiskey',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Canadian Mist',
    Type: 'Canadian Whiskey',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Whiskey',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Captain Morgan',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Rum',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Chivas Regal',
    Type: 'Blended Scotch Whiskey',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Scotch',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Christian Brothers Brandy',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Brandy',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Cream De Menthe',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Liqueur',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Crown Royal',
    Type: 'Canadian Whiskey',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Whiskey',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Crown Royal Apple',
    Type: 'Canadian Whiskey',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Whiskey',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Cruzan Rum',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Rum',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Deep Eddy Lemon',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Vodka',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Disarrono',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Liqueur',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Drambuie',
    Type: 'Blended Scotch Whiskey',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Whiskey',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Four Roses Yellow Label',
    Type: 'Kentucky Straight Bourbon Whiskey',
    Manufacturer: 'Four Roses Distillery',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Whiskey',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: "Gilby's",
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Gin',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'â€ŽGlenfiddich 12 Year',
    Type: 'Single Malt Scotch Whiskey',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Scotch',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Glenlivet 12 Year',
    Type: 'Single Malt Scotch Whiskey',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Scotch',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Grey Goose',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Vodka',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Hazelnut Liqueur',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Liqueur',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Hennessy',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Cognac',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Hot Damn',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Liqueur',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'J&B',
    Type: 'Blended Scotch',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Scotch',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Jack Daniels',
    Type: 'Tennessee Whiskey',
    Manufacturer: 'Jack Danielâ€™s Distillery',
    Origin: 'Lynchburg, Tennessee',
    Country: 'USA',
    ABV: '',
    DisplayGrouping: 'Whiskey',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'JÃ¤germeister',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Liqueur',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Jameson',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Whiskey',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Jim Beam',
    Type: 'Bourbon Whiskey',
    Manufacturer: 'Beam Suntory',
    Origin: 'Clermont, Kentucky',
    Country: 'USA',
    ABV: '',
    DisplayGrouping: 'Whiskey',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: "Jim Beam Devil's Cut",
    Type: 'Straight Bourbon Whisky',
    Manufacturer: 'Beam Suntory',
    Origin: 'Clermont, Kentucky',
    Country: 'USA',
    ABV: '',
    DisplayGrouping: 'Whiskey',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Johnnie Walker Black',
    Type: 'Blended Scotch Whiskey',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Scotch',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Jose Cuervo',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Tequila',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Kahlua',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Liqueur',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: 'yes',
    Name: 'Kamora Coffee Liqueur',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Liqueur',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Kerrygold Irish Cream Liqueur',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Liqueur',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: 'yes',
    Name: 'Kessler',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Whiskey',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Ketel One',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Vodka',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Makers Mark',
    Type: 'Small Batch Bourbon',
    Manufacturer: 'Beam Suntory',
    Origin: 'Loretto, Kentucky',
    Country: 'USA',
    ABV: '',
    DisplayGrouping: 'Whiskey',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Malibu Coconut Rum',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Rum',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Martel VS Single',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Cognac',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Melon Liqueur',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Liqueur',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'PatrÃ³n',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Tequila',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Peachtree Schnapps',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Liqueur',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Peppermint Schnapps',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Liqueur',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Pinnacle Blueberry',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Vodka',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Redemption',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Whiskey',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Rum Chata',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Liqueur',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: 'yes',
    Name: "Russell's Reserve",
    Type: 'Kentuckey Straight Bourbon Whiskey',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Whiskey',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: "Seagram's 7",
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Whiskey',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Skrewball Peanut Butter Whiskey',
    Type: 'Peanut Butter Whiskey',
    Manufacturer: 'Liquorama',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Whiskey',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Sloe Gin',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Liqueur',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Southern Comfort',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Whiskey',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Stetson Bourbon',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Whiskey',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Svedka Cucumber Lime',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Vodka',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Tanqueray',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Gin',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Templeton Rye',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Whiskey',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: "Tito's",
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Vodka',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: "Tom's Town",
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Whiskey',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: "Trail's End Whiskey",
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Whiskey',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Wild Turkey 101',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Whiskey',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Wild Turkey American Honey',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Whiskey',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Windsor',
    Type: 'Canadian Whiskey',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Whiskey',
    Image: '',
    Description: '',
  },
  {
    Category: 'Spirits',
    Price: '',
    Hidden: '',
    Name: 'Woodford Reserve',
    Type: '',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Whiskey',
    Image: '',
    Description: '',
  },
  {
    Category: 'Wine',
    Price: 6,
    Hidden: '',
    Name: 'Augusta Raspberry',
    Type: 'Sweet Red',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Reds',
    Image: '',
    Description: 'Semi-sweet fruit wine; made from 100% blackberries, crisp, fresh blackberry bouquet and flavor, slightly sweet finish.',
  },
  {
    Category: 'Wine',
    Price: 6,
    Hidden: '',
    Name: 'Augusta Vignoles',
    Type: 'Semi-Sweet White',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Whites',
    Image: '',
    Description: 'Semi-sweet white; Pineapple, tropical fruit bouquet, fresh crisp fruit body with a hint of sweetness.',
  },
  {
    Category: 'Wine',
    Price: 6,
    Hidden: 'yes',
    Name: 'Gnarly Head 1924',
    Type: 'Red Blend',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Reds',
    Image: '',
    Description:
      'Gnarly Head 1924 Double Black delivers rich aromas of blackberry preserves, cocoa, raspberry and caramel, setting the stage for a juicy core of concentrated, dense blackberry and fig jam flavors that finish with a hint of baking spice.',
  },
  {
    Category: 'Wine',
    Price: 9,
    Hidden: 'yes',
    Name: 'Gnarly Head Old Vine Zin',
    Type: 'Zinfindel',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Reds',
    Image: '',
    Description:
      'Old Vine Zinfandel has a gnarly core of rich, jammy blackberries and mocha flavors with layers of spice and vanilla balanced by complex French and American oak toast.',
  },
  {
    Category: 'Wine',
    Price: 4,
    Hidden: '',
    Name: 'House Cabernet Sauvignon',
    Type: 'Semi-Dry Red',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'House',
    Image: '',
    Description: 'A smooth wine that positively bursts with flavors of raspberry and blackberry jam',
  },
  {
    Category: 'Wine',
    Price: 4,
    Hidden: '',
    Name: 'House Chardonnay',
    Type: 'Dry White',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'House',
    Image: '',
    Description:
      'Big , bold flavor with a smooth finish, notes of crisp, green apples and sweet peaches. Acccented with hints of honey and vanilla.',
  },
  {
    Category: 'Wine',
    Price: 4,
    Hidden: '',
    Name: 'House Merlot',
    Type: 'Dry Red',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'House',
    Image: '',
    Description:
      'alluring  flavors of boysenberry and choclate with hints of anise and subtle tannins complementthe decadent,silky finish.',
  },
  {
    Category: 'Wine',
    Price: 4,
    Hidden: '',
    Name: 'House Moscato',
    Type: 'Sweet White',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'House',
    Image: '',
    Description:
      'A crisp, refreshing blend of juicy peaches and sweet apricots, finished with a tantalizing twist of lemon and citrus, paired seamlessly with cheeses and light desserts.',
  },
  {
    Category: 'Wine',
    Price: 4,
    Hidden: '',
    Name: 'House Pinot Grigio',
    Type: 'Semi-Dry White',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'House',
    Image: '',
    Description:
      'A light-bodied and refreshing comination of fresh, zesty citrus and crisp,refreshing green apples;with hints of jasmine and orange blossoms,paired perfectly with grilled fish,dips and salads.',
  },
  {
    Category: 'Wine',
    Price: 9,
    Hidden: '',
    Name: 'Kim Crawford',
    Type: 'Sauvignon Blanc',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Whites',
    Image: '',
    Description:
      'A fresh, juicy wine with vibrant acidity and plenty of weight. A ripe, tropical fruit flavor with passion fruit, melon,and grapefruit.',
  },
  {
    Category: 'Wine',
    Price: 9,
    Hidden: '',
    Name: 'L. Kreusch',
    Type: 'Riesling',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Whites',
    Image: '',
    Description:
      'Dry spicy white wine related to GewÃ¼rztraminer has an elegant floral aroma with tropical fruit notes. Pairs well with our Pork Chops and Spiced Apples.',
  },
  {
    Category: 'Wine',
    Price: 11,
    Hidden: '',
    Name: 'Ladies Who Shoot Their Lunch',
    Type: 'Chardonnay',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Whites',
    Image: '',
    Description:
      'Mineral notes acquiesce to intense aromas of melon, peach and subtle vanilla. The palate is complex and full bodied with balanced oak notes, a wonderfully creamy texture and great length of flavour.',
  },
  {
    Category: 'Wine',
    Price: 7,
    Hidden: '',
    Name: 'Mezzacorona',
    Type: 'Pinot Grigio',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Whites',
    Image: '',
    Description:
      'Delicious aromas of lemon, vanilla, baked apple crisp, orange, and grapefruit that are complemented by mouthwateringly fresh acidity and lingering citrus flavors. Pairs with our Buffalo Burger.',
  },
  {
    Category: 'Wine',
    Price: 9,
    Hidden: '',
    Name: 'Somerset Ridge Buffalo White',
    Type: 'Semi-Sweet White',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Whites',
    Image: '',
    Description: 'Luscious tropical fruit ï¬‚avors and a long, silky ï¬nish make this semi-sweet white a terriï¬c wine for any occasion!',
  },
  {
    Category: 'Wine',
    Price: 6,
    Hidden: '',
    Name: 'Stone Hill',
    Type: 'Moscato',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Whites',
    Image: '',
    Description: 'Luscious tropical fruit ï¬‚avors and a long, silky ï¬nish make this semi-sweet white a terriï¬c wine for any occasion!',
  },
  {
    Category: 'Wine',
    Price: 9,
    Hidden: '',
    Name: 'Wild Horse Cabernet Sauvignon',
    Type: 'Semi-Sweet Red',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Reds',
    Image: '',
    Description:
      'Aromas of blue fruit, candied currants, bramble fruits, forest floor, and oak spice. The mouthfeel is rich, with flavors of red velvet cake.',
  },
  {
    Category: 'Wine',
    Price: 11,
    Hidden: '',
    Name: 'Wild Horse Chardonnay',
    Type: 'Semi-Sweet White',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Whites',
    Image: '',
    Description:
      'Delicious aromas of lemon, vanilla, baked apple crisp, orange, and grapefruit that are complemented by mouthwateringly fresh acidity and lingering citrus flavors. Pairs with our Buffalo Burger.',
  },
  {
    Category: 'Wine',
    Price: 9,
    Hidden: '',
    Name: 'Wild Horse Merlot',
    Type: 'Dry Red',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Reds',
    Image: '',
    Description:
      'Aromas of fresh berry cobbler, baking spices, vanilla, and cocoa. The mouthfeel is nicely balanced with dark-red fruit flavors and soft, round, approachable tannins. Pairs well with Filet Mignon and Roasted Mushrooms.',
  },
  {
    Category: 'Wine',
    Price: 11,
    Hidden: '',
    Name: 'Wild Horse Pinot Noir',
    Type: 'Pinot Noir',
    Manufacturer: '',
    Origin: '',
    Country: '',
    ABV: '',
    DisplayGrouping: 'Reds',
    Image: '',
    Description:
      'Complex aromas of ripe cherry, dark fruit, nutmeg, earth, strawberry pie, and vanilla. The flavors are fresh, balanced, and bright.',
  },
  {
    Category: 'High-End Specialties & Imports',
    Price: 10,
    Hidden: '',
    Name: 'Mock Specialty Drink Name',
    Type: 'Belgian',
    Manufacturer: 'MillerCoors',
    Origin: 'Golden, Colorado',
    Country: 'USA',
    ABV: '',
    DisplayGrouping: 'On Tap',
    Image: '',
    Description:
      'Moderate body,smooth and creamy. burst of sweetness at first from the malt and coriander,hints of citrus peel and light pepper corns',
  },
];

export default mockData;
