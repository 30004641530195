import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea, CardMedia } from '@mui/material';
import { Link } from 'wouter';

type MenuCategoryCardProps = {
  categoryLabel: string;
  categoryRoute: string;
};

export default function OutlinedCard(props: MenuCategoryCardProps & { onClick?: () => void }) {
  return (
    <Box sx={{ minWidth: 350 }}>
      <Card sx={{ maxWidth: 345 }} elevation={8}>
        <CardActionArea onClick={props.onClick} href={props.categoryRoute}>
          <CardMedia component="img" height="140" image="/cocktailplaceholder.jpeg" alt="green iguana" />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {props.categoryLabel}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Description for drinks in category: {props.categoryLabel}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  );
}

export function MenuItemCategoryCard(props: MenuCategoryCardProps) {
  return (
    <Link href={props.categoryRoute}>
      <OutlinedCard {...props} />
    </Link>
  );
}
