import { useState } from 'react';
import useWebsocket, { MessageCallback, WebsocketEventDispatch } from './useWebsocket';

const WEBSOCKET_SERVER_URL =
  process.env.NODE_ENV === 'development'
    ? 'ws://localhost:8080'
    : // 'wss://websockets-server'
      `wss://menuapp-websockets-eokai3qyzq-uc.a.run.app`; // TODO pull from firebase env config

export default function WebsocketProvider() {
  const [initMessageState, setInitMessageState] = useState(null as any);

  const testFunction = (event: WebsocketEventDispatch) => {
    console.log('callback in WebsocketProvider received event', event);
    const data = event.data;
    console.log('data for setState', data);
    setInitMessageState(data);
  };

  const callbacks: MessageCallback[] = [
    {
      id: 'initial ws connection established event',
      testMatch: messageContent => {
        // actual text or object coming from WS server
        return messageContent === 'websocket initialized';
      },
      messageCallback: testFunction,
    },
  ];

  const wsHook = useWebsocket(WEBSOCKET_SERVER_URL, callbacks);

  console.log('ws status', wsHook);
  console.log('initMessageState', initMessageState);
  return initMessageState ? <div>websocket: {initMessageState}</div> : null;
}
