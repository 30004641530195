import { Divider, Typography } from '@mui/material';
import { BeverageMenuItem } from './mockData';
import useMenuData, { TopLevelCategory } from './useMenuData';

export default function TopLevelCategoryMenu(props: { type: string }) {
  const { categories } = useMenuData();

  const matchingCategory = categories[props.type];
  if (!matchingCategory) {
    return <>error: no category found for type "{props.type}"</>;
  }

  const categoryItems = matchingCategory.menuItems;
  return <>{categoryItems?.length > 0 ? <CategoryListing category={matchingCategory} /> : 'no data'}</>;
}

function CategoryListing({ category: { displayLabel, menuItems } }: { category: TopLevelCategory }) {
  return (
    <>
      <Typography variant="h4">{displayLabel}</Typography>
      <Divider sx={{ marginBottom: 3 }} />
      {menuItems.map((item, index) => (
        <div key={`item-${index}`}>{item.Name}</div>
      ))}
    </>
  );
}
