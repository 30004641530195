import { useEffect, useState } from "react";
import { Socket, io } from "socket.io-client";

// TODO possibly add generic to WebsocketEventDispatch
export type WebsocketEventDispatch = {
    timestamp: number,
    origin: string,
    type: string,
    data: any,
}

export type MessageCallback = {
    /**
     * Identifier string for the callback.
     */
    id: string,
    /**
    *  A callback that will be invoked for every new message to match callbacks with the WS message.
    *  The function will be passed the original WS message; return `true` to run the corresponding
    *  `messageCallback` function.
    */
    testMatch: (messageContent: any) => boolean
    /**
     * If a match is found, will be executed synchronously and passed the original WebSocket event.
     * 
     * @param event The originating WebSocket event
     * @returns anything
     */
    messageCallback: (event: WebsocketEventDispatch) => unknown
}

export default function useWebsocket(url: string, messageCallbacks?: MessageCallback[]) {
    const [ws, setWs] = useState(null as null | Socket);
    const [wsState, setWsState] = useState(null as null | number)
    const [wsMsg, setWsMsg] = useState(null as null | { wsMessageInHook: string })

    useEffect(() => {
        let socket = null as null | Socket
        const establishNewConnection = () => {

            console.log('establishing new socket.io connection')
            const socket = io(url);
            // const websocketConnection = new WebSocket(url);
            setWs(socket as any); // TODO fix

            socket.on("connect", () => {
                console.log('socket.io opened, id: ', ( socket.id ))
                // console.log(socket.id); // x8WIv7-mJelg7on_ALbx
              });
              
              socket.on("disconnect", () => {
                console.error('socket.io closed by server')
              });

            // websocketConnection.onopen = (event) => {
            //     console.log('websocket opened', ({ eventType: event.type }))
            //     setWsState(websocketConnection.readyState)
            // }

            // websocketConnection.onclose = (event) => {
            //     console.error('websocket closed by server', event.reason)
            //     setWsState(websocketConnection.readyState)
            // }

            // websocketConnection.onerror = (event) => {
            //     console.error('websocket error', ({ eventType: event.type }))
            //     setWsState(websocketConnection.readyState)
            // }

            // websocketConnection.onmessage = (event) => {
            //     // ! warning - do not attempt to copy event; MessageEvents do not clone well
            //     const data = event.data;
            //     console.log('received ws message data: ', data)

            //     if (messageCallbacks && messageCallbacks?.length > 0) {
            //         console.log('evaluating messageCallbacks', messageCallbacks)

            //         messageCallbacks.forEach(({ id, testMatch, messageCallback }) => {
            //             const matchResult = testMatch(data)
            //             if (matchResult) {
            //                 console.log(`match found for '${id}'`)
            //                 console.log('creating event for dispatch')
            //                 const forCallback: WebsocketEventDispatch = {
            //                     timestamp: event.timeStamp,
            //                     origin: event.origin,
            //                     type: event.type,
            //                     data: data,
            //                 }

            //                 console.log('sending event to callback', forCallback)
            //                 messageCallback(forCallback)
            //             } else {
            //                 console.log(`no match for ${id}`)
            //             }
            //         });
            //     }
            //     setWsMsg({ wsMessageInHook: data })
            // }
        }

        if (!ws) {
            establishNewConnection();
        }

        return () => { socket?.disconnect(); setWs(null) };
    }, [url]) // TODO this is probably missing dependencies `messageCallbacks` and `ws`

    // ws?.onopen = (ev)

    return ({ readyState: wsState, currentMessage: wsMsg })
}